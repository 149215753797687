import type { IPaginated } from "@edgetier/types";
import { InteractionType } from "common";
import { IUser } from "./users";

export enum ScorecardsFieldMapping {
    REVIEW_DATE = "create_date_time",
    AGENT_NAME = "agent_name",
    REVIEWER = "reviewer_name",
    STATUS = "scorecard_evaluation_state_id",
    SCORE = "score",
    RESULT = "has_passed",
    LANGUAGE = "interaction_language",
    SCORECARD = "scorecard_name",
}

export enum OptionIcons {
    smilingFace = 0,
    confusedFace = 1,
    sadFace = 2,
    n_a = 3,
}

export enum ScorecardEvaluationStatus {
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
}

export enum ScorecardStatus {
    Enabled = "ENABLED",
    Disabled = "DISABLED",
}

interface IScorecardUserDetails {
    userId: IUser["userId"];
    firstName: IUser["firstName"];
    surname: IUser["surname"];
}

export interface IScorecard {
    createdBy: IScorecardUserDetails;
    createdDateTime: Date;
    filters: {
        interactionType: InteractionType[];
        language: string[];
    };
    lastUpdatedBy: IScorecardUserDetails;
    lastUpdatedDateTime: Date;
    lastVersion: string | null;
    liveVersion: string | null;
    maxScore: number; // Rename to maxScore and assume it's calculated in the frontend
    minimumScorePercentage: number;
    scorecard: string;
    scorecardId: string;
    status: ScorecardStatus;
}

export interface IScorecardPaginatedParams {
    interactionId?: string;
    orderBy?: string;
    limit?: number;
}

export type IScorecardsPaginatedGetResponse = IPaginated<IScorecard>;

export interface IScorecardPostRequestBody
    extends Pick<IScorecard, "scorecard" | "minimumScorePercentage" | "filters"> {
    sections: IScorecardSectionPostBody[];
}

export type IScorecardPostResponse = Required<IScorecardPostRequestBody> &
    Pick<IScorecard, "scorecardId" | "liveVersion">;

export interface IScorecardPatchResponse {
    scorecardId: IScorecard["scorecardId"];
    scorecardVersionId: string;
    isEnabled: boolean;
}
export interface IScorecardPaginatedParams {
    interactionId?: string;
    orderBy?: string;
    limit?: number;
}

export interface IScorecardConfiguration extends IScorecard {
    scorecardVersionId: string;
    sections: Array<IScorecardSection>;
}

export interface IScorecardSection {
    sectionId: string;
    scorecardSection: string;
    maxScore: number; // Rename to maxScore and assume it's calculated in the frontend
    questions: Array<IScorecardQuestion>;
}

export interface IScorecardQuestion {
    questionId: string;
    isAutoScoring: boolean;
    label: string;
    guideline: string;
    options: Array<IScorecardQuestionOption>;
}

export interface IScorecardQuestionOption {
    optionId: string;
    iconId: OptionIcons;
    description: string;
    weight: number;
    isAutoFail: boolean;
}

export type IScorecardQuestionPostBodyOption = Omit<IScorecardQuestionOption, "optionId">;

export type IScorecardQuestionPostBody = Omit<IScorecardQuestion, "questionId" | "options"> & {
    options: Array<IScorecardQuestionPostBodyOption>;
};

export type IScorecardSectionPostBody = Omit<IScorecardSection, "sectionId" | "questions"> & {
    questions: Array<IScorecardQuestionPostBody>;
};

export interface IApiScorecardSection {
    scorecardSection: IScorecardSection["scorecardSection"];
    scorecardSectionId: IScorecardSection["sectionId"];
    questions: Array<IApiScorecardQuestion>;
}

export interface IApiScorecardQuestion {
    scorecardQuestion: IScorecardQuestion["label"];
    scorecardQuestionId: IScorecardQuestion["questionId"];
    guideline: IScorecardQuestion["guideline"];
    isAutoScoring: IScorecardQuestion["isAutoScoring"];
    options: Array<IApiScorecardQuestionOption>;
}

export interface IApiScorecardQuestionOption {
    scorecardSelectOptionId: IScorecardQuestionOption["optionId"];
    description: IScorecardQuestionOption["description"];
    icon: IScorecardQuestionOption["iconId"];
    isAutoFail: IScorecardQuestionOption["isAutoFail"];
    weight: IScorecardQuestionOption["weight"];
}

export interface IGetScorecardConfigurationResponse extends Omit<IScorecardConfiguration, "sections"> {
    createdByUserId: string;
    sections: Array<IApiScorecardSection>;
}

export interface IGetScorecardEvaluationExistsRequest {
    interactionId: string;
    agentId: string;
    scorecardId: string;
}

export interface IGetScorecardEvaluationExistsResponse {
    exists: boolean;
}

export interface IPostScorecardEvaluationsResponse {
    scorecardEvaluationId: string;
    hasPassed: boolean; // If the review has been approved
    score: number;
    interactionId: string;
    scorecardId: string;
    scorecardEvaluationStateId: string;
    reviewedByUserId: string;
    completedDateTime: string;
}

export interface IPostScorecardEvaluationAnswer {
    scorecardQuestionId: string;
    comment?: string;
    selectedOptionId: string;
}

export interface IPostScorecardEvaluationsRequest {
    scorecardVersionId: string;
    interactionId: string;
    agentId: string;
    score: number;
    comment?: string;
    maxScore: number;
    answers: Array<IPostScorecardEvaluationAnswer>;
}

export type IGetAvailableScorecardsResponse = Array<IScorecard>;

export interface IGetScorecardConfigurationParameters {
    scorecardId: IScorecard["scorecardId"];
}

// Context types
export interface IScorecardAnswer {
    questionId: IScorecardQuestion["questionId"];
    optionId: IScorecardQuestion["options"][0]["optionId"];
    comment?: string;
}
export interface IApiScorecardAnswer {
    scorecardQuestionId: IScorecardQuestion["questionId"];
    scorecardQuestionSelectOptionMappingId: IScorecardQuestion["options"][0]["optionId"];
    comment: string | null;
}

export interface IScorecardEvaluationSection {
    sectionId: string;
    score: number;
    finalMaxScore: number;
}

export interface IScorecardEvaluation {
    author?: string;
    createDateTime?: Date;
    globalScore: number;
    finalMaxScore: number;
    hasPassed: boolean;
    percentageOfAnsweredQuestions: number;
    isCompleted: boolean;
    sections: Array<IScorecardEvaluationSection>;
    comment: string | null;
    answers: Array<IScorecardAnswer>;
    isAutoFailed: boolean;
}

export interface IScorecardEvaluationEmailNotification {
    scorecardEvaluationNotificationId: string;
    scorecardEvaluationId: string;
    email: string;
    sentAt: Date;
    isSent: boolean;
}

export type IScorecardEvaluationNotification = IScorecardEvaluationEmailNotification;

export interface IScorecardEvaluationSummary {
    scorecardEvaluationId: string;
    createDateTime: Date;
    completedDateTime: Date;
    agent: {
        name: string;
        agentId: string;
        email: string | null;
    };
    reviewedByUser: {
        userId: string | null;
        firstName: string | null;
        surname: string | null;
    };
    scorecardEvaluationState: ScorecardEvaluationStatus;
    score: number;
    maxScore: number;
    hasPassed: boolean;
    interaction: {
        interactionType: InteractionType;
        subject: string;
        interactionId: string;
        languageIsoCode: string;
    };
    notificationDateTime: IScorecardEvaluationNotification["sentAt"] | null;
    scorecardName: IScorecard["scorecard"];
    comment: string;
}

export interface IScorecardEvaluationDetails
    extends Omit<IScorecardEvaluationSummary, "scorecardName" | "notificationDateTime"> {
    scorecardVersion: IScorecardConfiguration;
    notification: IScorecardEvaluationNotification | null;
    answers: Array<IScorecardAnswer>;
}

export interface IApiScorecardEvaluationSummary extends IScorecardEvaluationSummary {}

export interface IApiScorecardEvaluationDetails
    extends Omit<IScorecardEvaluationSummary, "scorecardName" | "notificationDateTime" | "answers"> {
    scorecardVersion: IGetScorecardConfigurationResponse;
    notification: IScorecardEvaluationNotification | null;
    answers: Array<IApiScorecardAnswer>;
}

export type IGetScorecardEvaluationsResponse = IPaginated<IApiScorecardEvaluationSummary>;
export type IGetScorecardEvaluationResponse = IApiScorecardEvaluationDetails;

export type IPostSendScorecardEvaluationNotificationParams = {
    scorecardEvaluationId: IScorecardEvaluationDetails["scorecardEvaluationId"];
};

export type IPostSendScorecardEvaluationNotificationQuery = {
    type: "email";
};
